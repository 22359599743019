:root {
  .questions-page {
    header {
      padding: 1.7vh 7vh;
      padding-top: 3vh;
      .header-wrapper {
        display: flex;
        width: 100%;
        align-items: flex-end;
        img {
          cursor: pointer;
          width: 10.2vh;
          border-radius: 100%;
        }
        .progess-wrapper {
          width: 100%;
          text-align: center;
          margin: 0 10%;
          h3 {
            font-family: var(--Catamaran-700);
            margin-bottom: 2vh;
          }
          .progress {
            --bs-progress-height: 1.7vh;
            --bs-progress-bg: rgb(255 255 255 / 80%);
            --bs-progress-border-radius: 2.375rem;
            --bs-progress-bar-bg: var(--progress-bar);
            .progress-bar {
              border-radius: 50px;
            }
          }
        }
      }
    }
    .content {
      margin-top: 2.2vh;
      min-height: calc(100vh - 28.6vh);
      max-height: calc(100vh - 28.6vh);
      overflow: auto;
      .question-box {
        background-image: url(../../Assets/Images/questions/question-background.png);
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        font-size: 3.3vh;
        margin: 0 auto;
        padding: 4.5vh 5vh;
        text-align: center;
        width: 135vh;
        height: 34vh;
        display: flex;
        align-items: center;
        .text-question {
          max-height: 100%;
          width: 100%;
          overflow-y: auto;
          padding: 2px 15px;
          display: flex;
          // align-items: center;
        }
        .video-box {
          position: relative;
          border-radius: 16px;
          background-color: #000000;
          overflow: hidden;
          width: 45.7vh;
          height: 25.6vh;
          display: flex;
          align-items: center;
          justify-content: center;
          > div {
            width: 45.7vh !important;
            height: 25.6vh !important;
          }
          .cover {
            height: 100%;
            object-fit: cover;
          }
          .playbutton {
            position: absolute;
            top: 31%;
            left: 33%;
            cursor: pointer;
            width: 9.6vh;
          }
        }
      }
      .form-check {
        display: flex;
        align-items: flex-start;
        margin-bottom: 3.3vh;
        @media (max-height: 559px) {
          margin-bottom: 2.3vh;
        }
        .form-check-input {
          cursor: pointer;
          background-image: url(../../Assets/Images/questions/checkbox.png);
          background-color: transparent;
          border: 0;
          width: 5.5vh;
          min-width: 5.5vh;
          height: 5.5vh;
          margin-right: 1.7vh;
          margin-top: 0;
          outline: 0 !important;
          border-radius: 100%;
          box-shadow: none !important;
          &:checked {
            background-image: url(../../Assets/Images/questions/filled-check.png);
          }
        }
        label {
          font-size: 3.3vh;
          padding-top: 0.5vh;
        }
      }
    }

    .options-row {
      max-width: 135vh;
      margin: auto;
      margin-top: 2.7vh;
    }

    .actions-button {
      margin-bottom: 2.2vh;
      margin-top: 2.2vh;
      z-index: 9;
      position: relative;
      display: flex;
      justify-content: center;
      .solid {
        font-family: var(--Catamaran-700);
        font-size: 4.4vh;
        background-image: url(../../Assets/Images/new-assets/qa-button.png);
        color: var(--default-color);
        width: 31vh;
        height: 10.3vh;
        background-color: transparent;
        border: none;
        background-size: contain;
        display: block;
        text-transform: uppercase;
        background-repeat: no-repeat;
        padding: 2vh 1.3vh 0.9vh 1.3vh;
      }
      .outline {
        font-family: var(--Catamaran-700);
        font-size: 4.4vh;
        border: 0.5vh solid var(--default-color);
        color: var(--default-color);
        width: 31vh;
        height: 10.3vh;
        background-color: transparent;
        background-size: contain;
        display: block;
        text-transform: uppercase;
        border-radius: 78px;
        margin-right: 15px;
        padding: 2vh 1.3vh 0.9vh 1.3vh;
      }
    }
  }
}
