:root {
  .homepage {
    background-image: url(../../Assets/Images/Home/dijklandar-homebg.png);
    min-height: 100vh;
    background-size: cover;
    background-position: bottom center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .home-pt {
      padding-top: 2.6vh;
    }
    .score-wrapper {
      padding-left: 7vh;
      .score-badge {
        cursor: pointer;
        z-index: 9;
        width: 10.6vh;
        object-fit: cover;
      }
      .custom-progress {
        width: 23.5vh;
        background-image: url(../../Assets/Images/Home/score-progress-bg.svg);
        background-size: contain;
        height: 6vh;
        margin-left: -1vh;
        background-repeat: no-repeat;
        padding-right: 2vh;
        background-position-y: center;
        margin-right: 1.8vh;
        .progress {
          --bs-progress-height: 3.8vh;
          margin-top: 1.2vh;
          border-radius: 0 7px 21px 0;
          --bs-progress-bg: transparent;
          .progress-bar {
            border-top-right-radius: 7px;
            border-bottom-right-radius: 21px;
            background-color: var(--blue-bar);
            background-image: url(../../Assets/Images/Home/score-dots.svg);
            background-position: right center;
          }
        }
      }
      .score {
        font-family: var(--Catamaran-600);
        font-size: 4.3vh;
        color: var(--text-secondary);
      }
    }
    .user-info {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 7vh;
      .username-dropdown {
        .btn {
          --bs-btn-color: var(--text-color);
          --bs-btn-bg: #ffffffcc;
          --bs-btn-border-color: #ffffffcc;
          --bs-btn-hover-color: var(--text-color);
          --bs-btn-hover-bg: #ffffffcc;
          --bs-btn-hover-border-color: #ffffffcc;
          --bs-btn-active-color: var(--text-color);
          --bs-btn-active-bg: #ffffffcc;
          --bs-btn-active-border-color: #ffffffcc;
          --bs-btn-disabled-color: var(--text-color);
          --bs-btn-disabled-bg: #ffffffcc;
          --bs-btn-disabled-border-color: #ffffffcc;
          --bs-btn-border-radius: 3rem;
          font-size: 3.7vh;
          margin-right: 5.4vh;
          padding: 0.7vh 8vh 0.7vh 3.3vh;
          &.dropdown-toggle::after {
            display: inline-block;
            margin-left: 0.6em;
            vertical-align: 0.1em;
          }
        }
        .dropdown-menu {
          top: 4.4vh !important;
          border: 0;
          border-radius: 0.7vh;
          box-shadow: 0 3px 10px #00000029;
          padding: 1vh;
          --bs-dropdown-min-width: 32vh;
          @media only screen and (max-height: 559px) {
            --bs-dropdown-min-width: 38vh;
            left: -7vh !important;
          }
          a.dropdown-item {
            font-size: 2.6vh;
            border-bottom: 1px solid #70707033;
            padding: 0.5vh 1.3vh;
            &:last-child {
              border-bottom: 0;
            }
            @media only screen and (max-height: 559px) {
              font-size: 3.6vh;
            }
          }
          &::before {
            content: "";
            border-bottom: 1em solid #fff;
            border-right: 0.5em solid transparent;
            border-top: 0;
            border-left: 0.5em solid transparent;
            position: absolute;
            top: -1.7vh;
            right: 4.4vh;
            z-index: -1;
          }
        }
      }
      img {
        z-index: 9;
        position: absolute;
        top: -2vh;
        right: 0;
        border-radius: 70px;
        // max-width: 98px;
        max-width: 10.5vh;
        @media only screen and (max-height: 420px) {
          top: -2vh;
        }
      }
    }
    .ranking {
      display: flex;
      align-items: center;
      padding-left: 7vh;
      padding-top: 2vh;
      img {
        z-index: 9;
        cursor: pointer;
        border-radius: 50px;
        width: 10.5vh;
      }
      .rank-score {
        width: 18.5vh;
        height: 6vh;
        display: flex;
        align-items: center;
        background-image: url(../../Assets/Images/Home/rank-bg.svg);
        background-position: right;
        background-size: cover;
        padding-right: 3.2vh;
        margin-left: -1.1vh;
        justify-content: flex-end;
        font-size: 3.3vh;
        strong {
          padding-left: 1vh;
        }
      }
    }
    .play-button {
      margin-bottom: 10px;
      z-index: 9;
      position: relative;
      .play {
        font-family: var(--Catamaran-700);
        font-size: 5.2vh;
        background-image: url(../../Assets/Images/new-assets/home-button.png);
        background-repeat: no-repeat;
        background-position: center;
        color: var(--default-color);
        width: 34.8vh;
        height: 11.8vh;
        background-color: transparent;
        border: none;
        margin: auto;
        background-size: contain;
        display: block;
        text-transform: uppercase;
        border-radius: 70px;
        padding: 2vh 1.3vh 0.9vh 1.3vh;
      }
    }
  }
}
